<!-- Finder de Expedientes Cias - Funerarias -->

<template>
  <div class="expMCiasfun" v-if="loaded">
    <v-sheet :elevation="4">
      
      <!-- Contenido -->     
      <div class="contenedor columna">

        <!-- Grid -->
        <baseGridMD
          :store="storeName"
          :headers="headerGrid"
          :items-per-page="-1"
          :expansible="`${this.storeName}${this.stIni.mView}`"
          dense
          @onEvent="execAccion">

            <!-- Slot Botonera Top Grid -->
            <template v-slot:gridTop="{}">
              <div>
                <baseBtraMto
                  v-if="$store.state[masterStore].estado!= 'nuevo'"
                  :perm="permMto"
                  :modulo="btGridCfg"
                  @onEvent="execAccion">
                </baseBtraMto>
              </div>
            </template>        

            <!-- Slot Acciones Row -->
            <template v-slot:acciones="{ item }">                
              <baseBtraMto
                :perm="permMto"
                :modulo="btRowCfg"
                @onEvent="execAccion({ ...$event, ...{item:item} })">
              </baseBtraMto>
            </template>

            <!-- Slot panel expansible -->
            <template v-slot:expansibleMX="{}">
              <expMCiasfunMX
                sync
                syncUpdate
                :syncAccion="syncAccionSend"
                :storeRaiz="storeName"
                :masterStore="storeName"
                :recordAux="record"
                :readOnly="readOnly"
                :viewMtoOnly="viewMtoOnly">
              </expMCiasfunMX>
            </template>
        </baseGridMD>
      </div>
    </v-sheet> 
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import expMCiasfunMX from "@/components/expMCiasfunMX";
  
  export default {
    mixins: [mixF],
    components: { baseGridMD, baseBtraMto, expMCiasfunMX },
    props: {
      masterStore: { type: String, default: '' },
      masterCt: { type:Object, default:()=> {}}
    },

    data() {
      return {
        stIni: {
          api: "expCiasfunF",
          titulo:"", 
          name: "expCiasfunF",
          mView: 'expCiasfunMX',
          pView:[],
          getRecordsArray:1,
          expansibleMX:'expCiasfunMX'
        },
        
      };
    },


    methods: {      
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // Cabecera Finder
        this.headers= [{
            // añadir en name los nombres de las cabeceras que coincidiran con las busquedas
            // si solo hubiera un elemento la cabecera tomará este por defecto 
            // Si no encontrara el nombre pondría el elemento 0
            name:[],
            fields:[
              { text: "Fecha", value: "fhhr", width:"13%", filtro:"fechahr" },
              { text: "Contacto", value: "contacto", width:"15%" },
              { text: "Teléfono", value: "tlf", width:"10%" },
              { text: "Empresa", value: "name_emp", width:"32%" },
              { text: "Origen", value: "name_emp_loc", width:"30%" }  
          ]}          
        ];            

        this.headerGrid= this.headerSet();       
      },


      iniDataApi() { 
        this.apiArgs.cias= ["", 'get', { id: 0, aux:this.stIni.getRecordsArray }, 'expediente'];
      },
      
      

      // FUNCIONES COMUNES PARA GESTIÓN Y APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      // gancho para búsqueda de datos particular si el origen es disparoSt y tiene getRecordsArray
      // apiCall para recargar los elementos del grid
      async callRecordsDisparo() {        
        let param = { apiArg: this.apiArgs.cias, args: { id:this.masterField }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        // Pendiente: controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return [];
        }

        return apiResult.r[0];
      }



      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE GESTIÓN
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    },


    computed: {

      record() {
        return this.$store.getters[this.masterStore + "/syncRecord"];
      },

    },
    
  };
</script>
