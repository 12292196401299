<!-- Finder expansible de Expedientes Ciasfun -->

<template>
  <div class="expMCiasfunMX" v-if="loaded">      
    <v-sheet class="contenedor" :elevation="0">

      <!-- Botones Mto -->
      <div class="pt-2 d-flex justify-left">         
        <baseBtraMto class="conflex"           
          :modulo="btMtoCfg" 
          :estado="estado"
          @onEvent="execAccion">        
        </baseBtraMto>
      </div>

      <!-- Controles del Mto -->
      <div style="margin-top:10px">
        <div class="conflex">
          <uiText
            style="width:40%"
            v-bind="$input"
            v-model="ct.contacto[2]"
            :label="ct.contacto[1]"
            :disabled="noEdit">
          </uiText>
          
          <uiText
            style="width:29%;margin-left:5px"
            v-bind="$input"
            v-model="ct.ref[2]"
            :label="ct.ref[1]"
            :disabled="noEdit">
          </uiText>

          <uiDate 
            style="width:29%;margin-left:5px"
            v-model="ct.fecha[2]"
            :label="ct.fecha[1]"          
            type="datetime-local"
            :disabled="noEdit">          
          </uiDate>
        </div>

        <div class="conflex">
          <uiMultiple
            style="flex: 1 1"
            :ctrl="ct.emp"
            :ct="ct"                  
            :disabled="noEdit">           
          </uiMultiple>          
        </div>

        <div class="conflex">
          <uiText  
            style="width:29%"
            v-bind="$input"
            v-model="ct.tlf[2]"
            :label="ct.tlf[1]"
            :disabled="noEdit">
          </uiText>

          <uiText          
            style="width:29%;margin-left:5px"
            v-bind="$input"
            v-model="ct.fax[2]"
            :label="ct.fax[1]"
            :disabled="noEdit">
          </uiText>

          <uiText          
            style="width:40%;margin-left:5px"
            v-bind="$input"
            v-model="ct.email[2]"
            :label="ct.email[1]"
            :disabled="noEdit">
          </uiText>
        </div>

        <uiObs3                       
          :record="record"
          :schema="ct"
          :schemaComponente="ct.obs"
          :edicion="!noEdit">                       
        </uiObs3>      
      </div>
    </v-sheet>       
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";  
  import baseBtraMto from "@/base/baseBtraMto";  
    
  export default {
    mixins: [mixM],
    components: { baseBtraMto },
    props: {     
    },

    
    data() {
      return {
        stIni: {
          api: "expCiasfunMX",
          name:"expCiasfunMX",          
          titulo:"",
          recordAccess:"local",
          mView:'',
          pView:[],
          relation:'id_exp'
        },
      
      };
    },  

    
    methods: {
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)"); 

      },


      // inicializo variables Btra Mto
      iniBtra() {
        this.btMtoCfg= JSON.parse(JSON.stringify(this.$cfe.btra.MX));
      },

    },


    computed: {

      relationID() {
        if (!this.recordAux || !Object.keys(this.recordAux).length ) return 0;
        return this.recordAux.id;
      },

    },

};
</script>
